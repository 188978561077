import React, {useState} from "react"
import HelpCenterLinks from "../../components/help-center/helpCenterLinks";
import dropDownIcon from "../../images/icons/drop-down-icon.svg"
import greaterThanIcon from "../../images/icons/greater-than-icon.svg"
import PremiumSupport from "../../components/help-center/helpCenterPremiumSupport";
import HelpCenterBlog from "../../components/help-center/helpCenterBlog"
import TorrentWebAd from "../../components/help-center/helpCenterTorrentWebAd"
import HelpCenterSearch from "../../components/help-center/helpCenterSearch";
import HelpCenterBreadCrumb from "../../components/help-center/helpCenterBreadCrumb";
import Axios from "axios";
import {Link} from "gatsby";
import HelpCenterRating from "../../components/help-center/helpCenterRating";
import useTranslations from "../../utils/useTranslations";

export default ({data, pageContext: {article, nav, productInfo,relatedArticles, text}}) => {
    const t = useTranslations(text)
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    let locale = "en"

    let dropDown = {};
    dropDown[article.folder_id] = true
    const [openDropDowns, updateOpenDropDowns] = useState(dropDown);

    function dropDownLogic(folderId) {
        if (openDropDowns.hasOwnProperty(folderId)) {
            let temp = {...openDropDowns};
            delete temp[folderId];
            updateOpenDropDowns(temp);
        } else {
            let temp = {...openDropDowns};
            temp[folderId] = true;
            updateOpenDropDowns(temp);
        }
    }

    function feedbackSubmit(isHelpful) {
        setFeedbackSubmitted(true)
        let url = `https://5tfj2zmxq2.execute-api.us-east-1.amazonaws.com/bt-help-center/thumbs?up=${isHelpful}&articleId=${article.id}`
        Axios.get(url)
    }

    function getBreadCrumb() {
        let breadCrumb = [{
            name: t("helpCenter"),
            link: HelpCenterLinks.homeLink,
            isLast: false,
        }];

        for (let i = 0; i < article.hierarchy.length; ++i) {
            let hierarchy = article.hierarchy[i];

            let link = "";

            if (hierarchy.type === "category") {
                link = HelpCenterLinks.solutionLink(hierarchy.data.language, hierarchy.data.id)
            }

            breadCrumb.push({
                name: article.hierarchy[i].data.name,
                link: link,
                isLast: i === article.hierarchy.length - 1
            })


        }
        return breadCrumb;

    }

    return (
        <>
        <div className={'help-center-article'}>
            <HelpCenterSearch text={text}/>
            <HelpCenterBreadCrumb breadCrumb={getBreadCrumb()} />

            <div className="help-center-body">

                <div className="main-content">
                    <div className="help-center-left">

                        <div className="nav-holder">

                        <h4>
                            { article.hierarchy[0].data.name}
                        </h4>


                        <div>
                            {nav.map(folder =>
                                <>
                                {folder.articleList && <div key={folder.id}>

                                    <div className="folder-header"
                                         onClick={() => dropDownLogic(folder.id)}> {folder.title}
                                        {openDropDowns.hasOwnProperty(folder.id) ?
                                            <img src={dropDownIcon} alt="show category" className="icon" /> :
                                            <img src={greaterThanIcon} alt="hide category" className="icon" />}</div>
                                    <div hidden={!openDropDowns.hasOwnProperty(folder.id)}>
                                        {folder.articleList.map(sideArticle =>
                                            <div
                                                className={"link-text " + (sideArticle.id === article.id ? "link-text-current" : "")}
                                                key={sideArticle.id}><Link
                                                to={HelpCenterLinks.articleLink(locale, sideArticle.id,sideArticle.title)}>{sideArticle.title}</Link></div>
                                        )}
                                    </div>
                                </div>}
                                </>

                            )}
                            </div>
                        </div>

                    </div>

                    <div className="help-center-main">

                        <h1>{article.title}</h1>

                        <div dangerouslySetInnerHTML={{__html: article.description}}></div>

                        <div className="helpful-container">

                            {!feedbackSubmitted &&
                                <>
                                    <div>{t("helpful")}</div>
                                    <div className="yes-no-container">
                                        <div className="user-selection"
                                             onClick={() => feedbackSubmit(true)}><span className="selection">{t("yes")}</span></div>
                                        <div className="selection-divider"></div>
                                        <div className="user-selection"
                                             onClick={() => feedbackSubmit(false)}><span className="selection">{t("no")}</span></div>
                                    </div>
                                </>

                            }
                            {feedbackSubmitted &&
                                <div>
                                    <b>
                                        {t("thankYouFeedBack")}
                                    </b>
                                </div>
                            }


                        </div>
                    </div>

                    <div className="help-center-right">
                        <div className="holder">
                            <div className="related-articles">
                                <div className="header-text">{t("relatedArticles")}</div>
                                {relatedArticles.map(related =>
                                    <div className="related-link" key={related.link}>
                                        <Link to={related.link} dangerouslySetInnerHTML={{__html:related.title}}></Link>
                                    </div>)}
                            </div>

                            <PremiumSupport text={text}/>

                            <HelpCenterBlog text={text}/>

                            <TorrentWebAd productInfo={productInfo} text={text} />

                            <HelpCenterRating text={text}/>

                        </div>
                    </div>
                </div>

                <div className="help-center-create-ticket">

                    <div className="line-one"> {t("cantFind")}</div>
                    <div className="line-two">{t("cantFindExpanded")}</div>

                    <Link to={HelpCenterLinks.submitTicket} className={"no-decoration"}><div
                        className="help-center-button submit-ticket">{t("submitTicket")}</div></Link>

                </div>
            </div>

        </div>
        </>
    )
}

